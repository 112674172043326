var ajaxoverlay = true;
jQuery(document).ajaxStart(function() {
    jQuery.LoadingOverlay("show");
});
jQuery(document).ajaxStop(function() {
    jQuery.LoadingOverlay("hide");
});

jQuery.fn.dataTable.ext.errMode = function (s, tn, msg) {
    if (s.jqXHR.status=='401') 
        window.location.href='/';
    else
        console.log(msg, tn);
};
jQuery(document).on("ajaxError", function(event) {
    if (event.status==401) window.location.href = "/";
});

jQuery(document).ready(function() {
    jQuery.ajaxSetup({
        headers: {
            'X-CSRF-TOKEN': jQuery('meta[name="csrf-token"]').attr('content')
        }
    });

    jQuery(function () {
        jQuery('[data-toggle="tooltip"]').tooltip();
    })

    jQuery(".datetimepicker").flatpickr({ enableTime: true,  dateFormat: "Y-m-d H:i", altFormat: "d/m/Y H:i", wrap: true });

    jQuery("#sidebarToggle, #sidebarToggleTop").on("click", function (e) {
        jQuery("body").toggleClass("sidebar-toggled");
        jQuery(".sidebar").toggleClass("toggled");
        jQuery(".sidebar").hasClass("toggled") && jQuery(".sidebar .collapse").collapse("hide");
    });

    jQuery('input[name=user]').autocomplete({
        source: function (request, response) {
            jQuery.getJSON('/user/autocomplete?search=' + request.term, function (data) {
                var array = jQuery.map(data, function (row) {
                    return {
                        value: row.name,
                        id: row.id
                    }
                });
                response(jQuery.ui.autocomplete.filter(array, request.term));
            });
        },
        minLength: 1,
        delay: 500,
        select: function (event, ui) {
            jQuery('input[name=user]').val(ui.item.label);
            jQuery('input[name=user_id]').val(ui.item.id);
        },
    });

    jQuery('.selectpicker').selectpicker();

});

// Bootstrap Validation
(function () {
    'use strict';
    window.addEventListener('load', function () {
        var forms = document.getElementsByClassName('needs-validation');
        var validation = Array.prototype.filter.call(forms, function (form) {
            form.addEventListener('submit', function (event) {
                if (form.checkValidity() === false) {
                    event.preventDefault();
                    event.stopPropagation();
                    jQuery('html, body').animate({
                        scrollTop: jQuery(".form-control:invalid").first().offset().top
                    }, 500);          
                }
                form.classList.add('was-validated');
            }, false);
        });
    }, false);
})();


