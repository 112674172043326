require('./bootstrap');
require('datatables.net-bs4');
require('datatables.net-buttons-bs4');
require('country-select-js');
import 'components-jqueryui/ui/widget';
import 'components-jqueryui/ui/widgets/autocomplete';
import 'components-jqueryui/ui/widgets/draggable';
import 'components-jqueryui/ui/widgets/sortable';
import 'components-jqueryui/ui/widgets/resizable';
window.flatpickr = require("flatpickr");
require('highlight-within-textarea');
require('@eastdesire/jscolor');
require('gasparesganga-jquery-loading-overlay');
require("bootstrap-select");
require('./theme');
window.Vapor = require('laravel-vapor');